import React from "react";
import { useTranslations } from "@/i18n/utils.ts";
import useGeocoding from "@/hooks/useGeocoding.ts";

type LocationDetailsProps = {
  lang: "ar" | "en";
};

const LocationDetails: React.FC<LocationDetailsProps> = ({ lang }) => {
  const t = useTranslations(lang);

  const locationGeocoding = useGeocoding();

  if (locationGeocoding.loading || !locationGeocoding.value) {
    return (
      <div className="max-[300px]:w-full">
        <div className="text-base-content flex gap-1 max-sm:text-sm">
          <span className="icon-[mingcute--location-line] w-6 h-6" />
          {t("contents.location")}
        </div>
        <div
          className={
            "mt-1 skeleton w-60 max-sm:w-32 max-[300px]:w-full max-lg:h-7 h-8 rounded-lg"
          }
        />
      </div>
    );
  }

  return (
    <div className="max-[300px]:w-full">
      <div className="text-base-content flex gap-1 max-sm:text-sm">
        <span className="icon-[mingcute--location-line] w-6 h-6"></span>
        {t("contents.location")}
      </div>
      <div className="text-2xl max-lg:text-lg max-md:w-60 max-sm:w-32 max-[300px]:w-full w-full truncate text-right">
        {locationGeocoding.value ? locationGeocoding.value.location_name : null}
      </div>
    </div>
  );
};

export default LocationDetails;
